import { Box, Grid, Stack } from "@mui/material"

import { Button } from "components/Button/Button"
import { Typography } from "components/Typography/Typography"
import { IStepsProps } from "../Invite"

import FeedbackImage from '../../../assets/feedback.svg'

export const StepFeedback = ({ handleNextStep }: IStepsProps) => {
    return (
        <Grid>
            <Stack>
                <Typography variant="h4">Boa sorte, participante!</Typography>
                <Typography variant="body1" mt={2}>Assim que o usuário fizer o primeiro acesso no MDS Cuidar utilizando seu link de indicação, você ganhará os pontos da campanha.</Typography>

                <Box marginY={5} display={'flex'} justifyContent={'center'}>
                    <img src={FeedbackImage} alt="Welcome" style={{ maxWidth: '350px', width: '100%', height: 'auto' }} />
                </Box>

            </Stack>

            <Stack>
                <Button variant="contained" fullWidth onClick={handleNextStep}>Enviar mais convites</Button>
            </Stack>
        </Grid>
    )
}