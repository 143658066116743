import React, { useState } from 'react'
import { Box, Grid, Stack } from "@mui/material"

import { Button } from "components/Button/Button"
import { Typography } from "components/Typography/Typography"
import { IStepsProps } from "../Invite"

import WelcomeImage from '../../../assets/welcome-invite.svg'
import { Modal } from 'components/Modal/Modal'

export const StepWelcome = ({ handleNextStep }: IStepsProps) => {
    const [modalPdf, setModalPdf] = useState(false)

    const urlPdf = 'https://arquivos.nhgtech.com.br/files/Regulamento_Convidar_Amigos.pdf';

    return (
        <Grid>
            <Stack>
                <Typography variant="h4">Convide colegas de empresa para o MDS Cuidar e concorra a prêmios!</Typography>
                <Typography variant="body1" mt={2}>Promova saúde e bem-estar! Envie convites, ilimitadamente, para seus colegas de empresa aproveitarem as funcionalidades do aplicativo.</Typography>

                <Box marginY={5} display={'flex'} justifyContent={'center'}>
                    <img src={WelcomeImage} alt="Welcome" style={{ maxWidth: '350px', width: '100%', height: 'auto' }} />
                </Box>

                <Typography>Quanto mais convites aceitos, mais chances você tem de conquistar nossos prêmios.</Typography>
            </Stack>

            <Stack mt={6} spacing={2} display="flex" alignItems='center' flex={1}>
                <Button variant="contained" fullWidth onClick={handleNextStep}>Convidar</Button>
                <Button fullWidth onClick={() => setModalPdf(true)}>Regulamento da campanha</Button>
            </Stack>

            <Modal
                title='Manual'
                isOpen={modalPdf}
                onClose={() => setModalPdf(!modalPdf)}
            >
                <object
                    width="100%"
                    height="480"
                    data={urlPdf}
                    type="application/pdf"
                >
                    <iframe
                        src={`https://nhg-pdf-render.onrender.com?pdfLink=${urlPdf}`}
                        width="100%"
                        height="480"

                    ></iframe>
                </object>
            </Modal>
        </Grid>
    )
}