import { useLocation } from 'react-router-dom'

import { Box, Grid, Stack } from '@mui/material'

import { Typography } from 'components/Typography/Typography'
import { IStepsProps } from '../Invite'
import {
  WhatsappIcon
} from 'react-share'
import { useEffect, useMemo, useState } from 'react'
import axios from 'axios'

export const StepSendInvite = ({ handleNextStep }: IStepsProps) => {
  const [sharedUrl, setSharedUrl] = useState('')

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const indicatorId = queryParams.get('indicador') || ''

  const buildLink = async (codigoIndicador: string | number) => {
    try {
      const link = `https://cuidarintegra.page.link/TG78?indicador=${codigoIndicador}`

      const response = await axios.post(
        'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBvtqDIhKUUjuwKT3_Pvj9Ma8qHdwdmlOc',
        {
          dynamicLinkInfo: {
            domainUriPrefix: 'https://cuidarintegra.page.link',
            link: link,
            androidInfo: {
              androidPackageName: 'br.com.healthyou.cuidarintegra'
            }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log(response.data)
      if (response.status === 200) {
        setSharedUrl(response.data.shortLink)
      } else {
        throw new Error('Erro ao encurtar o link')
      }
    } catch (error) {
      console.error('Erro:', error)
      throw error
    }
  }


  const titleMemo = useMemo(() => {
    const title =
      'Venha aproveitar todos os recursos do MDS Cuidar Autocuidado para melhorar sua saúde e bem-estar! Com o MDS Cuidar, você pode anexar exames e receitas, informar alergias, registrar e acompanhar seus indicadores de saúde, como peso, glicemia e muito mais. Basta você incluir código de indicação abaixo ao realizar o primeiro acesso ao aplicativo clicando no link:'

    return `${title} ${sharedUrl}\n\n *Código de indicação: ${indicatorId}*`
  }, [indicatorId, sharedUrl])

  const handleWhatsAppShare = () => {
    const encodedText = encodeURIComponent(titleMemo)
    const whatsappUrl = `https://wa.me/?text=${encodedText}`
    window.open(whatsappUrl, '_blank')
    handleNextStep()
  }

  useEffect(() => {
    if (indicatorId) {
      buildLink(indicatorId)
    }
  }, [indicatorId])

  return (
    <Grid>
      <Stack>
        <Typography variant="h4">
          Escolha como e para quem enviar o convite:
        </Typography>
      </Stack>

      {sharedUrl && (
        <Stack flexDirection="row" justifyContent="center" mt={4}>
          <button onClick={handleWhatsAppShare} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <WhatsappIcon size={80} round />
          </button>

          {/*
          <Box ml={3}>
            <FacebookMessengerShareButton
              url={sharedUrl}
              appId="1"
              onClick={handleSendInvite}
              title={titleMemo}
            >
              <FacebookMessengerIcon size={80} round />
            </FacebookMessengerShareButton>
          </Box>

      */}
        </Stack>
      )}
    </Grid>
  )
}
